<div class="account-item c11n-space-inset-2_5x" [formGroup]="accountData" *ngIf="accountData">
  <div class="item__heading-section">
    <p id="{{ accountIndex }}" class="item__name c11n-text-md-normal col-8">
      {{ accountData.get('accountName')?.value }}
    </p>

    <div class="item__checkbox col-2 mx-lg-1 col-lg-1 me-xl-2" *ngIf="!viewMode">
      <span id="allSelected" hidden>All checkboxes checked</span>
      <c11n-checkbox
        [hideLabel]="true"
        [variant]="'default'"
        [isIndeterminateClickable]="true"
        [isIndeterminate]="accountData.get('someHoldingsIncluded')?.value"
        (stateChange)="handleAccountSelection($event)"
      >
        <input
          #accountCheckBox
          c11nCheckboxInput
          id="{{ accountIndex }}-input"
          type="checkbox"
          name="account"
          value="{{ accountIndex }}"
          aria-live="polite"
          aria-atomic="true"
          attr.aria-labelledby="{{
            accountData.get('allHoldingsIncluded')?.value ? 'allSelected' : ''
          }} {{ accountIndex }} {{ hintErrorId }}"
          formControlName="allHoldingsIncluded"
        />
      </c11n-checkbox>
    </div>
  </div>
  <div class="item__details">
    <div class="item__holdings">
      <c11n-details
        [variant]="'primary'"
        [iconPosition]="'leading'"
        [labelWeight]="'bold'"
        [expandLabelText]="'Holdings'"
        [collapseLabelText]="'Holdings'"
        [isExpanded]="false"
        [labelAlign]="'left'"
        attr.aria-labelledby="{{ accountIndex }}"
      >
        <app-holdings
          [holdingsData]="accountHoldings"
          [accountIndex]="accountIndex"
          (accountIncluded)="handleHoldingsSelection($event)"
          [viewMode]="viewMode"
          [isParticipantAccount]="accountData.get('isParticipantAccount')?.value"
        ></app-holdings>
      </c11n-details>
    </div>
  </div>
</div>
