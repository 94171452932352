export const environment: any = {
  production: true,
  performance: false,
  deployment: 'production',
  internal: false,
  GLOBAL_HEADER_STYLES: 'https://ccc.vgcontent.info/vg-vgn/latest/css/vgn.min.css?appPrefix=FP3',
  GLOBAL_HEADER_JS: 'https://ccc.vgcontent.info/vg-vgn/latest/js/vgn.min.js?appPrefix=FP3',
  GLOBAL_HEADER_TAXONOMY: 'https://rgh.ecs.retp.c1.vanguard.com/taxonomy?noNav=true',
  accountGroupingURL: 'https://personal1.vanguard.com/ag3-account-grouping-consumer-api/get-views',
  createEndpointURL: 'https://personal1.vanguard.com/ag3-account-grouping-consumer-api/create',
  editEndpointURL: 'https://personal1.vanguard.com/ag3-account-grouping-consumer-api/edit',
  deleteEndpointURL: 'https://personal1.vanguard.com/ag3-account-grouping-consumer-api/delete',
  LOGGER_URL: 'https://personal1.vanguard.com/pw2-portfolio-watch-webservice/log',
  ADOBE_LAUNCH_JS:
    'https://corp.etm.assets.vgdynamic.info/files/retail_global_prd/379f26abac75/9d4ec58961b2/launch-ecb7d9153f04.min.js',
  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  ADOBE_DATA_LAYER:
    'https://personal1.vanguard.com/laa-ret-etm-adobe-launch-webapp/static/adobe-data-layer.js',
  LOGON_URL: 'https://logon.vanguard.com/logon',
  portfolioWatchURL: 'https://portfoliowatch.web.vanguard.com/',
  CONSUMER_API: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/',
  TEALEAF_LIB: 'https://personal1.vanguard.com/xs1-secure-site-consumer-api/tealeaf',
  IDLE_TIMER_CONFIG: JSON.stringify({
    idleTime: 900000,
    idleWarningTime: 180000,
    absoluteWarningTime: 180000,
    logOffRedirectURL: 'https://logon.vanguard.com/logon/idlelogoff',
    idleSessionRefreshURL: 'https://ccc.vanguard.com/api/idle-timeout-refresh',
    extendedUserToggleEnabled: true,
    clientServiceURL: 'https://personal1.vanguard.com/laa-ret-etm-adobe-launch-webapp/extendedUser',
  }),
  IDLE_TIMER_URL_CSS: 'https://ccc.vgcontent.info/vg-idle-timer/latest/css/idle_timer.min.css',
  IDLE_TIMER_URL_JS: 'https://ccc.vgcontent.info/vg-idle-timer/latest/js/idle_timer.min.js',
  APPDYNAMICS_CONFIG: JSON.stringify({
    appKey: 'AD-AAB-ABU-ESA',
    adrumExtUrl: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js',
    spa: { spa2: true },
    xd: { enable: false },
  }),
  CCC_APPDYNAMICS_JS: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js/app_dynamics.min.js',
};
