<div class="modify-group-page">
  <div class="modify-group-page__notifications container">
    <ng-container *ngIf="showModifyFailureBanner">
      <c11n-banner
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'error'"
        class="c11n-space-stack-5x c11n-space--force"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.MODIFY_ACCOUNT_GROUP_ERROR }}
        </div>
      </c11n-banner></ng-container
    >
    <ng-container *ngIf="showDeleteFailureBanner">
      <c11n-banner
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'error'"
        class="c11n-space-stack-5x c11n-space--force"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.DELETE_ACCOUNT_GROUP_ERROR }}
        </div>
      </c11n-banner></ng-container
    >
  </div>

  <ng-container *ngIf="investmentsList.length > 0">
    <form class="create-group-page__form container" [formGroup]="editFormGroup">
      <div class="c11n-text-xl c11n-space-stack-4x">{{ clientViewName }}</div>
      <div class="form__investments-list" formArrayName="accounts">
        <div role="heading" aria-level="2" class="list__title c11n-text-lg-bold">
          {{ appContent.ACCOUNTS_AND_HOLDINGS_TEXT }}
        </div>
        <c11n-hint-error
          [hasError]="investmentsFormArray.touched && investmentsFormArray.errors?.['noHoldingsSelectedError']"
          [errorText]="appContent.FORM_NO_INVESTMENTS_SELECTED"
          [size]="'large'"
          [hintErrorId]="'noInvestmentSelected'"
        ></c11n-hint-error>
        <div class="list__body">
          <ng-container
            *ngFor="let investment of investmentsFormArray.controls; let accountIndex = index"
          >
            <app-account-item
              [accountIndex]="accountIndex"
              [accountData]="investmentsFormArray.controls[accountIndex]"
              [viewMode]="viewMode"
              (participantAccountStatusChange)="handleParticipantAccounts()"
              [hintErrorId]="'noInvestmentSelected'"
            >
            </app-account-item>
          </ng-container>
        </div>
      </div></form
  ></ng-container>

  <div class="page__footer c11n-elevation-800 container-fluid">
    <div class="d-flex flex-column flex-sm-row gap-1point5">
      <c11n-button
        id="saveCustomGroupButton"
        labelText="Save"
        [fullWidth]="true"
        (clickEvent)="editAccountGroup()"
      ></c11n-button>
      <c11n-button
        labelText="Cancel"
        [variant]="'secondary'"
        [fullWidth]="true"
        (clickEvent)="cancelFormChanges()"
      ></c11n-button>
      <button
        type="button"
        class="c11n-button c11n-button--medium c11n-button--secondary delete-button"
        (click)="deleteModalDialog.openModalDialog()"
      >
        <span class="c11n-button__box">
          <span class="delete-icon">
            <svg class="c11n-trash-small">
              <use xlink:href="#trash-small"></use>
            </svg>
          </span>
          <span class="c11n-button__label">Delete group</span>
        </span>
      </button>
    </div>
  </div>
</div>

<app-cancel-modal #cancelModalDialog></app-cancel-modal>

<c11n-modal-dialog
  [modalId]="'deleteModal'"
  [variant]="'warning'"
  [headingText]="appContent.MODAL_DIALOG_DELETE_HEADING"
  [primaryButtonLabel]="'Delete'"
  [secondaryButtonLabel]="'Cancel'"
  (primaryClick)="deleteAccountGroup()"
  #deleteModalDialog
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md c11n-space-stack-2x">{{ appContent.MODAL_DIALOG_DELETE_BODY }}</p>
  </div>
</c11n-modal-dialog>
