import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import {
  AdobeLaunchService,
  WindowRef,
  AdobeLaunchModule,
} from '@vanguard/secure-site-components-lib';
import { AdobeLaunchEnvironment } from '@vanguard/secure-site-components-lib/lib/models/adobe-launch.model';

import { ConstellationIconsComponent } from './components/constellation-icons/constellation-icons.component';
import { CheckboxModule } from '@vg-constellation/angular-13/checkbox';
import { DetailsModule } from '@vg-constellation/angular-13/details';
import { InputModule } from '@vg-constellation/angular-13/input';
import { HintErrorModule } from '@vg-constellation/angular-13/hint-error';
import { LabelModule } from '@vg-constellation/angular-13/label';
import { ButtonModule } from '@vg-constellation/angular-13/button';
import { BannerModule } from '@vg-constellation/angular-13/banner';
import { LinkModule } from '@vg-constellation/angular-13/link';
import { SpinnerModule } from '@vg-constellation/angular-13/spinner';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ViewsCreateGroupComponent } from './components/views-create-group/views-create-group.component';
import { ViewsHomeComponent } from './components/views-home/views-home.component';
import { ModalDialogModule } from '@vg-constellation/angular-13/modal-dialog';
import { AccountItemComponent } from './components/account-item/account-item.component';
import { HoldingsComponent } from './components/holdings/holdings.component';
import { DropdownModule } from '@vg-constellation/angular-13/dropdown';
import { AccountSelectorComponent } from './components/account-selector/account-selector.component';
import { ViewsPageComponentComponent } from './components/views-page-component/views-page-component.component';
import { ViewsModifyGroupComponent } from './components/views-modify-group/views-modify-group.component';
import { CancelModalComponent } from './components/cancel-modal/cancel-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ConstellationIconsComponent,
    ViewsCreateGroupComponent,
    ViewsHomeComponent,
    AccountItemComponent,
    HoldingsComponent,
    AccountSelectorComponent,
    ViewsPageComponentComponent,
    ViewsModifyGroupComponent,
    CancelModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    CommonModule,
    ReactiveFormsModule,

    AdobeLaunchModule,
    // c11n modules
    ModalDialogModule.forRoot(),
    CheckboxModule,
    DetailsModule,
    ButtonModule,
    LabelModule,
    HintErrorModule,
    InputModule,
    BannerModule,
    DropdownModule,
    LinkModule,
    SpinnerModule,
  ],
  providers: [
    WindowRef,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: function (
        http: HttpClient,
        windowRef: WindowRef,
        adobe: AdobeLaunchService,
      ): any {
        const $window: Window = windowRef.nativeWindow;
        return (): any => {
          http
            .get(`${environment.CONSUMER_API}adobe`, { withCredentials: true })
            .toPromise()
            .then(() => {
              adobe.initialize(
                { pageTemplate: 'portfoliowatch-views' },
                environment.deployment as AdobeLaunchEnvironment,
              );
              // 'unhide' app <body> now that page is sure to be displayed.
              $window.document.body.hidden = false;
            })
            .catch(() => {
              if (!environment.internal) {
                $window.document.location.href = environment.LOGON_URL;
              } else {
                $window.document.body.hidden = false;
              }
              // Ensure that app initialization is aborted since we're going to the (external) logon page.
              return Promise.reject();
            });
        };
      },
      deps: [HttpClient, WindowRef, AdobeLaunchService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
