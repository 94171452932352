import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContentType } from 'src/app/constants/interfaces';
import { appContent } from 'src/app/constants/template';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent {
  appContent: ContentType = appContent;
  @ViewChild('cancelModalDialog') cancelModalDialog: any;

  constructor(private router: Router) {}

  routeToMainPage(): void {
    this.router.navigate(['managegroups/view']);
  }

  openModalDialog(): void {
    this.cancelModalDialog.openModalDialog();
  }
}
