import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownMenuData, DropdownMenuItem } from '@vg-constellation/angular-13/dropdown';
import { VanguardStandardAccountGroups } from 'src/app/constants/template';
import { viewData } from 'src/app/models/account-grouping.model';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
})
export class AccountSelectorComponent {
  groups: DropdownMenuData = { groups: [{ items: [] }] };
  @Input() activeAccountGroupViewId: string = '';
  @Input() set portfolioWatchViews(portfolioWatchViews) {
    this.setupDropdownGroups(portfolioWatchViews);
  }
  @Output() groupSelected: EventEmitter<DropdownMenuItem> = new EventEmitter<DropdownMenuItem>();

  setupDropdownGroups(portfolioWatchViews: viewData[]): void {
    const items: DropdownMenuItem[] = portfolioWatchViews.map((item) => {
      return {
        labelText: this.getDisplayName(item.viewName),
        id: item.clientViewId,
      };
    });
    this.groups = { groups: [{ items: items }] };
  }
  handleDropDownChange(event: any): void {
    this.groupSelected.emit(event);
  }

  private getDisplayName(viewName: string): string {
    let displayName: string = viewName;
    if (displayName.toLowerCase() === VanguardStandardAccountGroups.MANAGED_VIEW_NAME) {
      displayName = VanguardStandardAccountGroups.MANAGED_DISPLAY_NAME;
    } else if (displayName.toLowerCase() === VanguardStandardAccountGroups.SELF_MANAGED_VIEW_NAME) {
      displayName = VanguardStandardAccountGroups.SELF_MANAGED_DISPLAY_NAME;
    }

    return displayName;
  }
}
