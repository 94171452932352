<div class="item__holdings-table">
  <table class="c11n-table c11n-table--small" aria-label="Holdings summary">
    <thead class="table__head">
      <tr class="c11n-table__tr">
        <th scope="col" class="c11n-table__thead-th holding-symbol">Symbol</th>
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">Name</th>
        <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">Balance</th>
        <th
          scope="col"
          class="c11n-table__thead-th c11n-table__thead-th--align-right head__select-col"
          *ngIf="!viewMode"
        >
          Select
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <ng-container *ngFor="let holding of holdingsData.controls; let holdingIndex = index">
        <tr [formGroup]="getGroup(holdingIndex)">
          <th scope="row" class="c11n-table__td c11n-table__td--align-left holding-symbol col-2">
            {{ holding.get('holdingSymbol')?.value }}
          </th>
          <td
            id="fundname-{{ accountIndex }}-{{ holdingIndex }}"
            class="c11n-table__td c11n-table__td--align-left col-5"
          >
            {{ holding.get('holdingName')?.value }}
          </td>
          <td class="c11n-table__td c11n-table__td--align-left col-3">
            {{ holding.get('holdingBalance')?.value | currency }}
          </td>
          <td class="c11n-table__td c11n-table__td--align-right col-2 col-lg-1" *ngIf="!viewMode">
            <div class="body__checkbox-col">
              <c11n-checkbox
                [hideLabel]="true"
                [focusLabel]="true"
                [labelText]="'Select Holding'"
                (stateChange)="handleHoldingItemChange()"
              >
                <input
                  c11nCheckboxInput
                  #holdingCheckbox
                  class="c11n-checkbox__input"
                  id="holding-{{ holdingIndex }}-account-{{ accountIndex }}"
                  type="checkbox"
                  name="holding-{{ holdingIndex }}"
                  value="{{ holdingIndex }}"
                  attr.aria-labelledby="fundname-{{ accountIndex }}-{{ holdingIndex }}"
                  formControlName="isHoldingSelected"
                />
              </c11n-checkbox>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
