import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountData, PortfolioWatchViews, viewData } from 'src/app/models/account-grouping.model';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { LoggerCode, VanguardStandardAccountGroups } from 'src/app/constants/template';

@Injectable({
  providedIn: 'root',
})
export class AccountGroupingDataService {
  accountGroupingURL: string;
  allInvestments = new BehaviorSubject<AccountData[]>([]);
  viewsData = new BehaviorSubject<viewData[]>([]);
  accountGroupsList = new BehaviorSubject<string[]>([]);
  portfolioWatchViews: viewData[] = [];
  appDataLoading$ = new BehaviorSubject<boolean>(true);
  appDataFailed$ = new BehaviorSubject<boolean>(false);
  appDataLoadSuccess$ = new BehaviorSubject<boolean>(false);
  customAccountGroupCount = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient, private loggerService: LoggerService) {
    this.initialSetup();
    this.loadViewsData();
  }

  initialSetup(): void {
    this.accountGroupingURL = environment.accountGroupingURL;
  }

  loadViewsData(): void {
    this.appDataLoadSuccess$.next(false);
    this.appDataLoading$.next(true);
    this.getViewsData().subscribe({
      next: (res: PortfolioWatchViews) => {
        this.appDataLoading$.next(false);

        if (res) {
          this.appDataLoadSuccess$.next(true);

          res.portfolioWatchViews?.forEach((item, i) => {
            if (!item.clientViewId) {
              item.clientViewId = `${i}`;
            }
          });
          this.setAllInvestmentsList(res);
          this.portfolioWatchViews = res.portfolioWatchViews;
          this.viewsData.next(res.portfolioWatchViews);
          this.loadAccountGroupsList(res.portfolioWatchViews);
        } else {
          this.appDataFailed$.next(true);
        }
        this.loggerService.info(
          {
            message: 'Account grouping data loaded successfully',
          },
          LoggerCode.ACCOUNT_GROUPING_SUCCESS,
        );
      },
      error: () => {
        this.appDataLoading$.next(false);
        this.appDataFailed$.next(true);
        this.loggerService.error(
          { message: 'Failed to load account grouping data' },
          LoggerCode.ACCOUNT_GROUPING_FAILURE,
        );
      },
    });
  }

  private getViewsData(): Observable<PortfolioWatchViews> {
    return this.httpClient.get<PortfolioWatchViews>(this.accountGroupingURL, {
      withCredentials: true,
    });
  }

  setAllInvestmentsList(response: PortfolioWatchViews): void {
    if (response) {
      let allInvestments: AccountData[] =
        response.portfolioWatchViews?.find((obj) => obj?.viewName === 'All Accounts')?.accounts ||
        [];
      this.allInvestments.next(allInvestments);
    }
  }

  getAllInvestments(): Observable<AccountData[]> {
    return this.allInvestments.asObservable();
  }

  getPwViews(): Observable<viewData[]> {
    return this.viewsData.asObservable();
  }

  getaccountGroupsList(): Observable<string[]> {
    return this.accountGroupsList.asObservable();
  }

  loadAccountGroupsList(pwViews): void {
    const list: string[] = pwViews.map((accountGroup: viewData) => accountGroup.viewName);
    this.accountGroupsList.next(list);
    this.loadCustomGroupsCount(pwViews);
  }

  loadCustomGroupsCount(pwViews): void {
    const customGroupsList: string[] = pwViews.filter((grp: viewData) => {
      const accGrpNameLowerCase: VanguardStandardAccountGroups =
        grp.viewName.toLowerCase() as VanguardStandardAccountGroups;
      return !Object.values(VanguardStandardAccountGroups).includes(accGrpNameLowerCase);
    });
    this.customAccountGroupCount.next(customGroupsList.length);
  }

  getCustomGroupCount(): Observable<number> {
    return this.customAccountGroupCount.asObservable();
  }
}
