<c11n-modal-dialog
  [modalId]="'cancelModal'"
  [variant]="'warning'"
  [headingText]="appContent.MODAL_DIALOG_CANCEL_HEADING"
  [primaryButtonLabel]="'Exit'"
  [secondaryButtonLabel]="'Keep editing'"
  (primaryClick)="routeToMainPage()"
  #cancelModalDialog
>
  <div c11n-modal-dialog-body>
    <p class="c11n-text-md c11n-space-stack-2x">
      {{ appContent.MODAL_DIALOG_CANCEL_REMOVE_CHANGES }}
    </p>
  </div>
</c11n-modal-dialog>
