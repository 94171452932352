import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { ContentType } from 'src/app/constants/interfaces';
import { appContent } from 'src/app/constants/template';
import { AccountGroupingDataService } from 'src/app/services/account-grouping-data/account-grouping-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-views-managegroups',
  templateUrl: './views-home.component.html',
  styleUrls: ['./views-home.component.scss'],
})
export class ViewsHomeComponent implements OnDestroy, OnInit {
  pageTitle: string = '';
  currentPath: string = '';
  showBackLink: boolean = false;
  showExitLink: boolean = true;
  accountGroupName: string;
  pwURL: string;
  appContent: ContentType = appContent;
  backLinkElem: ElementRef;
  private onDestroy: Subject<void> = new Subject<void>();
  @ViewChild('cancelModalDialog') cancelModalDialog: any;
  @ViewChild('back', { static: false, read: ElementRef }) set backLink(elem: ElementRef) {
    if (elem) {
      this.backLinkElem = elem;
      this.backLinkElem.nativeElement.focus();
      this.backLinkElem.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          event.preventDefault();
          this.cancelFormChanges();
        }
      });
    }
  }
  isLoading$: BehaviorSubject<boolean>;
  isFailure$: BehaviorSubject<boolean>;
  isSuccess$: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private accountGroupingDataServie: AccountGroupingDataService,
  ) {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy),
        filter((routerEvent) => routerEvent instanceof ActivationEnd),
      )
      .subscribe((routerEvent: ActivationEnd | any) => {
        const data: any = routerEvent.snapshot.data;
        if (data?.title) {
          this.pageTitle = data.title;
        }
        this.setPathInformation(routerEvent);
      });
  }

  ngOnInit(): void {
    this.pwURL = environment.portfolioWatchURL;
    this.isLoading$ = this.accountGroupingDataServie.appDataLoading$;
    this.isFailure$ = this.accountGroupingDataServie.appDataFailed$;
    this.isSuccess$ = this.accountGroupingDataServie.appDataLoadSuccess$;
  }

  setPathInformation(routerEvent: ActivationEnd): void {
    const path: string = routerEvent.snapshot.routeConfig?.path || '';
    if (path === 'managegroups/create' || path === 'managegroups/edit') {
      this.showBackLink = true;
      this.showExitLink = false;
    } else if (path === 'managegroups/view') {
      this.showBackLink = false;
      this.showExitLink = true;
    } else {
      this.showBackLink = false;
      this.showExitLink = true;
    }
  }

  getAccountGroupName($event): void {
    if ($event) {
      this.accountGroupName = $event;
    }
  }

  cancelFormChanges(): void {
    this.cancelModalDialog.openModalDialog();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
