<div class="default-view-page container-fluid">
  <div class="default-view-page__notifications container">
    <ng-container *ngIf="showCreationSuccessBanner">
      <c11n-banner
        class="c11n-space-stack-4x c11n-space--force"
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'success'"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.CREATE_ACCOUNT_GROUP_SUCCESS }}
        </div>
      </c11n-banner>
    </ng-container>
    <ng-container *ngIf="showModifySuccessBanner">
      <c11n-banner
        class="c11n-space-stack-4x c11n-space--force"
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'success'"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.MODIFY_ACCOUNT_GROUP_SUCCESS }}
        </div>
      </c11n-banner>
    </ng-container>
    <ng-container *ngIf="showDeleteSuccessBanner">
      <c11n-banner
        class="c11n-space-stack-4x c11n-space--force"
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'success'"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.DELETE_ACCOUNT_GROUP_SUCCESS }}
        </div>
      </c11n-banner>
    </ng-container>
  </div>

  <div *ngIf="viewMode" class="c11n-space-stack-4x container">
    <div class="row">
      <div class="col-12 col-md-3 col-lg-6">
        <app-account-selector
          [activeAccountGroupViewId]="activeAccountGroupViewId"
          [portfolioWatchViews]="portfolioWatchViews$ | async"
          (groupSelected)="handleGroupSelection($event)"
        ></app-account-selector>
      </div>
      <div class="account-dropdown col-12 col-md-4 col-lg-3" *ngIf="!hideEdit">
        <c11n-button
          class="button-group"
          [size]="'medium'"
          [variant]="'primary'"
          [labelText]="'Edit group'"
          [iconName]="'edit'"
          [iconPosition]="'leading'"
          [fullWidth]="true"
          (clickEvent)="navigateToEditGroup()"
        ></c11n-button>
      </div>
      <div class="account-dropdown col-12 col-md-4 col-lg-3">
        <c11n-button
          class="button-group"
          [size]="'medium'"
          [variant]="!hideEdit ? 'secondary' : 'primary'"
          [labelText]="'Create new group'"
          [iconName]="'plus'"
          [iconPosition]="'leading'"
          [fullWidth]="true"
          (clickEvent)="navigateToCreateGroup()"
        ></c11n-button>
      </div>
    </div>
  </div>

  <div class="create-group-page container">
    <ng-container *ngIf="investmentsList.length > 0; else noData">
      <form class="create-group-page__form" [formGroup]="createFormGroup">
        <div class="form__investments-list" formArrayName="accounts">
          <div role="heading" aria-level="2" class="list__title c11n-text-lg-bold">
            {{ appContent.ACCOUNTS_AND_HOLDINGS_TEXT }}
          </div>
          <div class="list__body">
            <ng-container
              *ngFor="let investment of investmentsFormArray.controls; let accountIndex = index"
            >
              <app-account-item
                [accountIndex]="accountIndex"
                [accountData]="investmentsFormArray.controls[accountIndex]"
                [viewMode]="viewMode"
              >
              </app-account-item>
            </ng-container>
          </div>
        </div></form
    ></ng-container>
  </div>
</div>
<ng-template #noData>
  <div class="c11n-text-lg">{{ appContent.VIEW_PAGE_NO_INVESTMENTS_AVAILABLE }}</div>
</ng-template>
