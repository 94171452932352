import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsCreateGroupComponent } from './components/views-create-group/views-create-group.component';
import { ViewsModifyGroupComponent } from './components/views-modify-group/views-modify-group.component';
import { ViewsPageComponentComponent } from './components/views-page-component/views-page-component.component';

export const routes: Routes = [
  {
    path: 'managegroups/view',
    component: ViewsPageComponentComponent,
    data: { title: 'View and manage custom groups' },
  },
  {
    path: 'managegroups/create',
    component: ViewsCreateGroupComponent,
    data: { title: 'Create new custom group' },
  },
  {
    path: 'managegroups/edit',
    component: ViewsModifyGroupComponent,
    data: { title: 'Edit custom group' },
  },
  {
    path: '**',
    redirectTo: 'managegroups/view',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
