import { ContentType } from './interfaces';

export enum VanguardStandardAccountGroups {
  allAccounts = 'all accounts',
  nonRetirement = 'non-retirement',
  retirement = 'retirement',
  education = 'education',
  MANAGED_VIEW_NAME = 'managed',
  MANAGED_DISPLAY_NAME = 'advised',
  SELF_MANAGED_VIEW_NAME = 'self-managed',
  SELF_MANAGED_DISPLAY_NAME = 'self-directed',
}

export enum HoldingsTableStates {
  all = 'All holdings selected',
  some = 'Some holdings selected',
  none = 'no holdings selected',
  participant = 'participant holding selected',
}

export const MAX_CUSTOM_ACCOUNT_GROUPS_COUNT: number = 10;

export const appContent: ContentType = {
  ACCOUNTS_AND_HOLDINGS_TEXT: 'Accounts and holdings',
  ACCOUNT_GROUPING_DATA_FAILURE:
    'We are having an issue displaying custom groups. Please reload the page in a few minutes.',
  CREATE_ACCOUNT_GROUP_SUCCESS: 'Your custom group has been successfully created.',
  CREATE_ACCOUNT_GROUP_ERROR: 'We were unable to save your custom group. Please try again.',
  MAX_ACCOUNT_GROUPS_REACHED: `You have already reached the maximum of ${MAX_CUSTOM_ACCOUNT_GROUPS_COUNT} custom groups. Delete a custom group in order to create a new one.`,
  MODIFY_ACCOUNT_GROUP_SUCCESS: 'Changes to your custom group have been saved.',
  MODIFY_ACCOUNT_GROUP_ERROR:
    'We were unable to save the changes to your custom group. Please try again.',
  DELETE_ACCOUNT_GROUP_SUCCESS: 'Your custom group has been successfully deleted.',
  DELETE_ACCOUNT_GROUP_ERROR: 'We were unable to delete your group. Please try again.',
  FORM_NO_GROUP_ENTERED: 'Group name cannot be empty.',
  FORM_NON_UNIQUE_GROUP_NAME: 'Another group already has this name. Please choose a new name.',
  FORM_MAX_CHARACTERS_REACHED: 'Group name should be less than 50 characters.',
  FORM: 'Please use letters and numbers only.',
  FORM_NO_INVESTMENTS_SELECTED: 'Select at least one account/holding.',
  VIEW_PAGE_NO_INVESTMENTS_AVAILABLE: 'You dont have any accounts in this group.',
  MODAL_DIALOG_CANCEL_REMOVE_CHANGES: `If you exit without saving, you'll lose your changes.`,
  MODAL_DIALOG_CANCEL_HEADING: 'Discard changes?',
  MODAL_DIALOG_DELETE_HEADING: 'Are you sure you want to delete this group?',
  MODAL_DIALOG_DELETE_BODY: `This action can't be undone.`,
};

export enum LoggerCode {
  ACCOUNT_GROUPING_SUCCESS = 'V001',
  ACCOUNT_GROUPING_FAILURE = 'V002',
}

export enum LoggerLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  ALERT = 'alert',
}
