import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { AccountData, HoldingData } from 'src/app/models/account-grouping.model';
import { CreateFormObject } from 'src/app/models/create-group.model';
import { environment } from 'src/environments/environment';
import { AccountGroupingDataService } from '../account-grouping-data/account-grouping-data.service';
import { PwViewsFormsService } from '../pw-views-forms/pw-views-forms.service';
import {
  AccountPayload,
  createViewPayload,
} from 'src/app/interfaces/create-account-grouping.interface';

@Injectable({
  providedIn: 'root',
})
export class PwViewsCreateService {
  xsrfToken: string;
  tokenHeader: HttpHeaders;
  createEndpointURL: string;
  private _lastCreatedAccountGroupName: string = '';

  constructor(
    private httpClient: HttpClient,
    private token: HttpXsrfTokenExtractor,
    private formsService: PwViewsFormsService,
    private accountGroupingDataService: AccountGroupingDataService,
  ) {
    this.xsrfToken = this.token.getToken() ? this.token.getToken()! : 'X-XSRF-TOKEN-DUMMY';
    this.tokenHeader = new HttpHeaders({
      'X-XSRF-TOKEN': this.xsrfToken,
      'Content-type': 'application/json',
    });
    this.createEndpointURL = environment.createEndpointURL;
  }

  // Convert create object and save portfolio watch view.
  createAccountGroup(): Observable<any> {
    let postURL: string = this.createEndpointURL;
    let currentCreateObject: CreateFormObject = this.formsService.createObject.getValue();
    let payload: any = JSON.stringify(this.mapCreateObjectToPayload(currentCreateObject));

    return this.httpClient
      .post(postURL, payload, { withCredentials: true, headers: this.tokenHeader })
      .pipe(
        tap((res) => {
          this.accountGroupingDataService.loadViewsData();
          this.setupLastCreatedAccountGroup();
          return res;
        }),
        catchError((error) => {
          console.log('errored here', error);
          return error;
        }),
      );
  }

  mapCreateObjectToPayload(createObj: CreateFormObject): any {
    //TODO: map create form object to the payload. Currently using mock.
    let selectedAccounts: AccountPayload[] = this.getSelectedAccounts(createObj);
    let mappedPayload: createViewPayload = {
      primaryPortfolioWatchView: false,
      vistaAccount: createObj.areParticipantAccountsSelected,
      viewName: createObj.viewName,
      accounts: selectedAccounts,
      groupType: 'CUSTOM',
    };
    return mappedPayload;
  }

  getSelectedAccounts(createObj: CreateFormObject): AccountPayload[] {
    let allInvestments: AccountData[] = JSON.parse(
      JSON.stringify(this.formsService.allInvestments),
    );

    let selectedAccounts: AccountData[] = allInvestments.filter((account: AccountData) => {
      let allHoldingsIncluded: boolean = !!createObj.accounts.find(
        (acct) => acct.accountId === account.accountId,
      )?.allHoldingsIncluded;
      let someHoldingsIncluded: boolean = !!createObj.accounts.find(
        (acct) => acct.accountId === account.accountId,
      )?.someHoldingsIncluded;
      return allHoldingsIncluded || someHoldingsIncluded;
    });

    selectedAccounts = this.extractAccountsForVistaAccounts(selectedAccounts);

    let selectedAccountsWithSelectedHoldings: AccountPayload[] = selectedAccounts.map(
      (account: AccountData) => {
        const actualHoldingsLength: number = account.accountPositionIds?.length;
        let selectedHoldings: HoldingData[] = account.accountPositionIds?.filter(
          (holding: HoldingData) => {
            let isHoldingSelected: boolean = !!createObj.accounts
              .find((acct) => acct.accountId === account.accountId)
              ?.holdings.find((holdingRef) => holdingRef.holdingId === holding.positionId)
              ?.isHoldingSelected;

            return isHoldingSelected;
          },
        );
        const selectedHoldingsLength: number = selectedHoldings?.length;

        let acctPayload: AccountPayload = {
          accountId: account.accountId,
          accountPositionIds: selectedHoldings?.map((holding) => {
            return { positionId: holding.positionId };
          }),
          accountType: account.accountType,
          balance: account.balance,
          includeAllPositions: actualHoldingsLength === selectedHoldingsLength ? 'Y' : 'N',
        } as AccountPayload;

        acctPayload.accountPositionIds =
          acctPayload.includeAllPositions === 'Y' ? [] : acctPayload.accountPositionIds;

        return acctPayload;
      },
    );

    return selectedAccountsWithSelectedHoldings;
  }

  extractAccountsForVistaAccounts(accounts: AccountData[]): AccountData[] {
    return accounts?.filter((account) => !account.isVistaAccount);
  }

  setupLastCreatedAccountGroup(): void {
    this.lastCreatedAccountGroup = this.formsService.createObject.getValue().viewName;
  }

  set lastCreatedAccountGroup(groupName: string) {
    this._lastCreatedAccountGroupName = groupName;
  }

  get lastCreatedAccountGroup(): string {
    return this._lastCreatedAccountGroupName;
  }
}
