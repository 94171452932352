import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CheckboxState } from '@vg-constellation/angular-13/checkbox';
import { HoldingsTableStates } from 'src/app/constants/template';

@Component({
  selector: 'app-account-item',
  templateUrl: './account-item.component.html',
  styleUrls: ['./account-item.component.scss'],
})
export class AccountItemComponent {
  @Input() accountData: FormGroup = new FormGroup({
    accountId: new FormControl(''),
    accountName: new FormControl(''),
    holdings: new FormArray([new FormGroup({ holdingId: new FormControl('') })]),
    allHoldingsIncluded: new FormControl(false),
    someHoldingsIncluded: new FormControl(false),
  });

  @Input() viewMode: boolean = true;
  @Input() accountIndex: number;
  @Input() containsHoldings: boolean = true;
  @Input() hintErrorId: string;

  @Output() participantAccountStatusChange: EventEmitter<null> = new EventEmitter();

  inIt: boolean = true;

  get accountHoldings(): FormArray {
    return this.accountData.get('holdings') as FormArray;
  }

  get isParticipantAccount(): boolean {
    return this.accountData.get('isParticipantAccount')?.value;
  }

  handleHoldingsSelection(state: string): void {
    if (state === HoldingsTableStates.all) {
      this.setAccountItemCheckboxControls(false, true);
    } else if (state === HoldingsTableStates.none) {
      this.setAccountItemCheckboxControls(false, false);
    } else if (state === HoldingsTableStates.some) {
      this.setAccountItemCheckboxControls(true, false);
    } else if (state === HoldingsTableStates.participant && this.isParticipantAccount) {
      this.participantAccountStatusChange.emit();
    }
  }

  setAccountItemCheckboxControls(
    areSomeHoldingsSelected: boolean,
    areAllHoldingsSelected: boolean,
  ): void {
    this.accountData.patchValue({
      someHoldingsIncluded: areSomeHoldingsSelected,
      allHoldingsIncluded: areAllHoldingsSelected,
    });
  }

  handleAccountSelection(status: CheckboxState): void {
    if (!this.inIt) {
      if (this.isParticipantAccount) {
        this.participantAccountStatusChange.emit();
      } else {
        if (status.isChecked) {
          this.accountData.get('someHoldingsIncluded')?.setValue(false);

          this.accountHoldings.controls.forEach((control) => {
            control.get('isHoldingSelected')?.setValue(true);
          });
        } else {
          this.accountData.get('someHoldingsIncluded')?.setValue(false);

          this.accountHoldings.controls.forEach((control) => {
            control.get('isHoldingSelected')?.setValue(false);
          });
        }
      }
    }

    this.inIt = false;
  }
}
