import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, skipWhile, mergeMap } from 'rxjs';
import { ContentType } from 'src/app/constants/interfaces';
import { appContent } from 'src/app/constants/template';
import {
  AccountFormObject,
  CreateFormObject,
  HoldingFormObject,
} from 'src/app/models/create-group.model';
import { AccountGroupingDataService } from 'src/app/services/account-grouping-data/account-grouping-data.service';
import { PwViewsFormsService } from 'src/app/services/pw-views-forms/pw-views-forms.service';
import { PwViewsModifyService } from 'src/app/services/pw-views-modify/pw-views-modify.service';
import { customValidateAccountsList } from '../views-create-group/views-create-group.component';
import { AccountData, viewData } from 'src/app/models/account-grouping.model';

@Component({
  selector: 'app-views-modify-group',
  templateUrl: './views-modify-group.component.html',
  styleUrls: ['./views-modify-group.component.scss'],
})
export class ViewsModifyGroupComponent implements OnInit, OnDestroy {
  appContent: ContentType = appContent;
  inputGroupName: string = '';
  investmentsList: AccountFormObject[];
  emptyErrorState: boolean = false;
  duplicateErrorState: boolean = false;
  accountGroupList: Array<string> = ['Retirement', 'Managed'];
  heading: string;
  showModifyFailureBanner: boolean = false;
  showDeleteFailureBanner: boolean = false;
  private onDestroy: Subject<void> = new Subject<void>();
  viewMode: boolean = false;
  accounts: AccountData[] = [];

  editFormGroup: FormGroup;
  clientViewId: string;
  clientViewName: string;
  @ViewChild('cancelModalDialog') cancelModalDialog: any;

  constructor(
    private pwViewsModifyService: PwViewsModifyService,
    private pwViewsFormsService: PwViewsFormsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountGroupingDataService: AccountGroupingDataService,
  ) {
    this.editFormGroup = new FormGroup({
      viewName: new FormControl(''),
      clientViewId: new FormControl(''),
      accounts: new FormArray([], customValidateAccountsList()),
      areParticipantAccountsSelected: new FormControl(false),
    });
    this.investmentsList = [];
  }

  ngOnInit(): void {
    this.clientViewId = this.activatedRoute.snapshot.queryParams['clientViewId'];
    this.accountGroupingDataService
      .getPwViews()
      .pipe(
        takeUntil(this.onDestroy),
        skipWhile((views) => views.length === 0),
        mergeMap((data) => {
          const currentView: viewData | undefined = data.find(
            (obj) => obj?.clientViewId === this.clientViewId,
          );
          this.accounts = currentView?.accounts || [];
          this.clientViewName = currentView?.viewName || '';
          this.editFormGroup.get('viewName')?.patchValue(this.clientViewName);
          this.editFormGroup.get('clientViewId')?.patchValue(this.clientViewId);
          return this.pwViewsFormsService.getDisplayObject();
        }),
      )
      .subscribe((editObj: any) => {
        this.investmentsList = editObj.accounts as any;
        this.generateFormControls();
      });

    this.editFormGroup.valueChanges.subscribe((value: CreateFormObject) => {
      this.pwViewsModifyService.updateEditObject(value);
    });
  }

  get investmentsFormArray(): FormArray<FormGroup> {
    return this.editFormGroup.get('accounts') as FormArray;
  }

  getAccountAtIndex(acctIndex): FormGroup {
    return this.investmentsFormArray.at(acctIndex) as FormGroup;
  }

  accountHoldings(acctIndex): FormArray {
    return this.investmentsFormArray.at(acctIndex).get('holdings') as FormArray;
  }

  generateFormControls(): void {
    this.investmentsList.forEach((account: AccountFormObject, acctIndex) => {
      const current: AccountData | undefined = this.accounts.find(
        (item) => item.accountId === account.accountId,
      );

      this.investmentsFormArray.push(
        new FormGroup({
          accountName: new FormControl(account.accountName),
          accountId: new FormControl(account.accountId),
          holdings: new FormArray([]),
          allHoldingsIncluded: new FormControl(false),
          someHoldingsIncluded: new FormControl(false),
          isParticipantAccount: new FormControl(account.isParticipantAccount),
        }),
      );

      account?.holdings?.forEach((holding: HoldingFormObject) => {
        const currentHolding: any = current?.accountPositionIds?.find(
          (item) => item.positionId === holding.holdingId,
        );

        this.accountHoldings(acctIndex).push(
          new FormGroup({
            holdingName: new FormControl(holding.holdingName),
            holdingId: new FormControl(holding.holdingId),
            holdingSymbol: new FormControl(holding.holdingSymbol),
            holdingBalance: new FormControl(holding.holdingBalance),
            isHoldingSelected: new FormControl(currentHolding ? true : false),
          }),
        );
      });

      this.initializeAccountItemControls(acctIndex);
    });

    this.initializeParticipantAccountControls();
  }

  initializeAccountItemControls(acctIndex: number): void {
    this.initAllAccountHoldingsControl(acctIndex);
    this.initSomeAccountHoldingsControl(acctIndex);
  }

  initAllAccountHoldingsControl(acctIndex: number): void {
    const allHoldingsIncluded: boolean = this.accountHoldings(acctIndex).value.every(
      (val) => val.isHoldingSelected === true,
    );
    this.getAccountAtIndex(acctIndex).patchValue({ allHoldingsIncluded: allHoldingsIncluded });
  }

  initSomeAccountHoldingsControl(acctIndex: number): void {
    const someHoldingsIncluded: boolean = this.accountHoldings(acctIndex).value.some(
      (val) => val.isHoldingSelected === true,
    );
    if (!this.getAccountAtIndex(acctIndex).get('allHoldingsIncluded')?.value) {
      this.getAccountAtIndex(acctIndex).patchValue({
        someHoldingsIncluded: someHoldingsIncluded,
      });
    }
  }

  initializeParticipantAccountControls(): void {
    const areParticipantAccountsIncluded: boolean =
      !!this.accounts.find((account) => account.isVistaAccount === true) || false;
    if (areParticipantAccountsIncluded) {
      this.handleParticipantAccounts();
    }
  }

  editAccountGroup(): void {
    this.showModifyFailureBanner = false;
    this.pwViewsModifyService.updateEditObject(this.editFormGroup.value);
    this.editFormGroup.markAllAsTouched();
    if (this.editFormGroup.valid) {
      this.pwViewsModifyService
        .editAccountGroup()
        .pipe(takeUntil(this.onDestroy))
        .subscribe({
          next: () => {
            this.pwViewsFormsService.showModifySuccessBanner = true;
            this.router.navigate(['managegroups/view']);
          },
          error: () => {
            this.showModifyFailureBanner = true;
          },
        });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  cancelFormChanges(): void {
    this.cancelModalDialog.openModalDialog();
  }

  handleParticipantAccounts(): void {
    const currentState: boolean = this.editFormGroup.get('areParticipantAccountsSelected')?.value;
    let newState: boolean = false;
    this.investmentsFormArray.controls.forEach((investControl, index) => {
      if (investControl.get('isParticipantAccount')?.value) {
        newState = !currentState;
        this.accountHoldings(index).controls.forEach((accountControl) => {
          accountControl.get('isHoldingSelected')?.setValue(newState);
        });
        investControl.get('allHoldingsIncluded')?.setValue(newState);
      }
    });
    this.editFormGroup.get('areParticipantAccountsSelected')?.setValue(newState);
  }

  deleteAccountGroup(): void {
    this.showDeleteFailureBanner = false;
    this.pwViewsModifyService
      .deleteAccountGroup(this.editFormGroup.value['clientViewId'])
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: () => {
          this.pwViewsFormsService.showDeleteSuccessBanner = true;
          this.router.navigate(['managegroups/view']);
        },
        error: () => {
          this.showDeleteFailureBanner = true;
          window.scrollTo({ top: 0, left: 0 });
        },
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
