import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatestWith, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
import { ContentType } from 'src/app/constants/interfaces';
import { appContent, MAX_CUSTOM_ACCOUNT_GROUPS_COUNT } from 'src/app/constants/template';
import {
  AccountFormObject,
  CreateFormObject,
  HoldingFormObject,
} from 'src/app/models/create-group.model';
import { AccountGroupingDataService } from 'src/app/services/account-grouping-data/account-grouping-data.service';
import { PwViewsCreateService } from 'src/app/services/pw-views-create/pw-views-create.service';
import { PwViewsFormsService } from 'src/app/services/pw-views-forms/pw-views-forms.service';

export function customValidateAccountsList(): ValidatorFn {
  return (formArray: any): ValidationErrors | null => {
    let valid: boolean = true;
    let statuses: boolean[] = formArray.controls.map((account) => {
      return (
        account.get('allHoldingsIncluded')?.value || account.get('someHoldingsIncluded')?.value
      );
    });
    valid = statuses.some((status) => status === true);
    return valid ? null : { noHoldingsSelectedError: 'no holdings selected' };
  };
}

export function customValidateUniqueName(accountGroupsList: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let valid: boolean = true;
    accountGroupsList.forEach((val, index, arr) => {
      arr[index] = val.toLowerCase();
    });
    valid = !accountGroupsList?.includes(control.value.toLowerCase());
    return valid ? null : { nonUniqueGroupName: 'group name is not unique' };
  };
}

@Component({
  selector: 'app-managegroups-create-group',
  templateUrl: './views-create-group.component.html',
  styleUrls: ['./views-create-group.component.scss'],
})
export class ViewsCreateGroupComponent implements OnInit, OnDestroy {
  appContent: ContentType = appContent;
  inputGroupName: string = '';
  investmentsList: AccountFormObject[] = [];
  emptyErrorState: boolean = false;
  duplicateErrorState: boolean = false;
  heading: string;
  showFailureBanner: boolean = false;
  private onDestroy: Subject<void> = new Subject<void>();
  viewMode: boolean = false;
  accounts: AccountFormObject[] = [];
  accountGroupsList$: Observable<string[]>;
  maxCustomGroupsReached: boolean;

  createFormGroup: FormGroup;
  @ViewChild('cancelModalDialog') cancelModalDialog: any;

  constructor(
    private pwViewsCreateService: PwViewsCreateService,
    private pwViewsFormsService: PwViewsFormsService,
    private agService: AccountGroupingDataService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.accountGroupsList$ = this.agService.getaccountGroupsList();

    this.pwViewsFormsService
      .getDisplayObject()
      .pipe(
        combineLatestWith(this.accountGroupsList$),
        takeUntil(this.onDestroy),
        skipWhile(([obj, list]) => obj.accounts?.length === 0 || list.length === 0),
      )
      .subscribe(([createObj, accountGroupsList]) => {
        this.investmentsList = createObj.accounts as any;
        this.accounts = [...this.investmentsList];
        this.declareFormControls(accountGroupsList);
        this.assignFormControls();
      });

    this.agService
      .getCustomGroupCount()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (customGroupCount) => {
          this.maxCustomGroupsReached = !!(customGroupCount >= MAX_CUSTOM_ACCOUNT_GROUPS_COUNT);
        },
      });
  }

  declareFormControls(accountGroupsList: string[]): void {
    this.createFormGroup = new FormGroup({
      viewName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        //Lookahead assertion to check string contains atleast one non-space char
        Validators.pattern('^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 ]+$'),
        customValidateUniqueName(accountGroupsList),
      ]),
      accounts: new FormArray([], customValidateAccountsList()),
      areParticipantAccountsSelected: new FormControl(false),
    });

    this.createFormGroup.valueChanges.subscribe((value: CreateFormObject) => {
      this.pwViewsFormsService.updateCreateObject(value);
    });
  }

  get investmentsFormArray(): FormArray<FormGroup> {
    return this.createFormGroup.get('accounts') as FormArray;
  }

  get viewNameInput(): FormControl {
    return this.createFormGroup.get('viewName') as FormControl;
  }

  accountHoldings(acctIndex): FormArray {
    return this.investmentsFormArray.at(acctIndex).get('holdings') as FormArray;
  }

  assignFormControls(): void {
    this.accounts.forEach((account: AccountFormObject, index) => {
      this.investmentsFormArray.push(
        new FormGroup({
          accountName: new FormControl(account.accountName),
          accountId: new FormControl(account.accountId),
          holdings: new FormArray([]),
          allHoldingsIncluded: new FormControl(false),
          someHoldingsIncluded: new FormControl(false),
          isParticipantAccount: new FormControl(account.isParticipantAccount),
        }),
      );

      account?.holdings?.forEach((holding: HoldingFormObject) => {
        this.accountHoldings(index).push(
          new FormGroup({
            holdingName: new FormControl(holding.holdingName),
            holdingId: new FormControl(holding.holdingId),
            holdingSymbol: new FormControl(holding.holdingSymbol),
            holdingBalance: new FormControl(holding.holdingBalance),
            isHoldingSelected: new FormControl(false),
          }),
        );
      });
    });
  }

  createAccountGroup(): void {
    this.showFailureBanner = false;
    this.createFormGroup.markAllAsTouched();
    if (this.createFormGroup.valid && !this.maxCustomGroupsReached) {
      this.pwViewsCreateService.createAccountGroup().subscribe({
        next: () => {
          this.pwViewsFormsService.showCreationSuccessBanner = true;
          this.router.navigate(['managegroups/view']);
        },
        error: () => {
          this.showFailureBanner = true;
        },
      });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  cancelFormChanges(): void {
    this.cancelModalDialog.openModalDialog();
  }

  handleParticipantAccounts(): void {
    const currentState: boolean = this.createFormGroup.get('areParticipantAccountsSelected')?.value;
    let newState: boolean = false;
    this.investmentsFormArray.controls.forEach((investControl, index) => {
      if (investControl.get('isParticipantAccount')?.value) {
        newState = !currentState;
        this.accountHoldings(index).controls.forEach((accountControl) => {
          accountControl.get('isHoldingSelected')?.setValue(newState);
        });
        investControl.get('allHoldingsIncluded')?.setValue(newState);
      }
    });
    this.createFormGroup.get('areParticipantAccountsSelected')?.setValue(newState);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
