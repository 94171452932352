<div class="create-group-page">
  <div class="create-group-page__notifications container">
    <ng-container *ngIf="maxCustomGroupsReached">
      <c11n-banner
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'error'"
        class="c11n-space-stack-5x c11n-space--force"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.MAX_ACCOUNT_GROUPS_REACHED }}
        </div>
      </c11n-banner></ng-container
    >
    <ng-container *ngIf="showFailureBanner">
      <c11n-banner
        [size]="'small'"
        [hasCloseControl]="false"
        [variant]="'error'"
        class="c11n-space-stack-5x c11n-space--force"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.CREATE_ACCOUNT_GROUP_ERROR }}
        </div>
      </c11n-banner></ng-container
    >
  </div>
  <ng-container *ngIf="investmentsList.length > 0">
    <form class="create-group-page__form container" [formGroup]="createFormGroup">
      <div class="form__group-name-input c11n-space-stack-4x col-11 col-md-5">
        <label for="viewNameInput" c11n-label>Group Name</label>
        <c11n-input class="group-name-input" [size]="'medium'">
          <input
            c11nInput
            type="text"
            inputmode="text"
            id="viewNameInput"
            name="info"
            [placeholder]="'Enter custom group name'"
            formControlName="viewName"
            [attr.aria-describedby]="'input-error'"
            required
          />
        </c11n-input>
        <div
          id="input-error"
          class="input__error"
          *ngIf="viewNameInput.invalid && (viewNameInput.touched || viewNameInput.dirty)"
        >
          <c11n-hint-error
            [hasError]="viewNameInput.errors?.['maxlength']"
            [errorText]="appContent.FORM_MAX_CHARACTERS_REACHED"
            [size]="'large'"
            [hintErrorId]="'maxlengthError'"
          ></c11n-hint-error>
          <c11n-hint-error
            [hasError]="viewNameInput.errors?.['pattern']"
            [errorText]="appContent.FORM"
            [size]="'large'"
            [hintErrorId]="'patternError'"
          ></c11n-hint-error>
          <c11n-hint-error
            [hasError]="viewNameInput.errors?.['required']"
            [errorText]="appContent.FORM_NO_GROUP_ENTERED"
            [size]="'large'"
            [hintErrorId]="'noGroupEntered'"
          ></c11n-hint-error>
          <c11n-hint-error
            [hasError]="viewNameInput.errors?.['nonUniqueGroupName']"
            [errorText]="appContent.FORM_NON_UNIQUE_GROUP_NAME"
            [size]="'large'"
            [hintErrorId]="'nonUniqueGroupError'"
          ></c11n-hint-error>
        </div>
      </div>
      <div class="form__investments-list" formArrayName="accounts">
        <div role="heading" aria-level="2" class="list__title c11n-text-lg-bold">
          {{ appContent.ACCOUNTS_AND_HOLDINGS_TEXT }}
        </div>
        <c11n-hint-error
          [hasError]="investmentsFormArray.errors?.['noHoldingsSelectedError']  && investmentsFormArray.touched"
          [errorText]="appContent.FORM_NO_INVESTMENTS_SELECTED"
          [size]="'large'"
          [hintErrorId]="'noInvestmentSelected'"
        ></c11n-hint-error>
        <div class="list__body">
          <ng-container
            *ngFor="let investment of investmentsFormArray.controls; let accountIndex = index"
          >
            <app-account-item
              [accountIndex]="accountIndex"
              [accountData]="investmentsFormArray.controls[accountIndex]"
              [viewMode]="viewMode"
              (participantAccountStatusChange)="handleParticipantAccounts()"
              [hintErrorId]="'noInvestmentSelected'"
            >
            </app-account-item>
          </ng-container>
        </div>
      </div></form
  ></ng-container>

  <div class="page__footer c11n-elevation-800 container-fluid">
    <div class="d-flex gap-1point5 flex-column flex-sm-row">
      <c11n-button
        id="saveCustomGroupButton"
        labelText="Save"
        [fullWidth]="true"
        (clickEvent)="createAccountGroup()"
      ></c11n-button>
      <c11n-button
        labelText="Cancel"
        [variant]="'secondary'"
        [fullWidth]="true"
        (clickEvent)="cancelFormChanges()"
      ></c11n-button>
    </div>
  </div>
</div>

<app-cancel-modal #cancelModalDialog></app-cancel-modal>
