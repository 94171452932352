import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { HoldingsTableStates } from 'src/app/constants/template';

@Component({
  selector: 'app-holdings',
  templateUrl: './holdings.component.html',
  styleUrls: ['./holdings.component.scss'],
})
export class HoldingsComponent implements AfterViewInit {
  @Input() holdingsData: FormArray = new FormArray([
    new FormGroup({ holdingId: new FormControl('1'), isHoldingSelected: new FormControl(true) }),
    new FormGroup({ holdingId: new FormControl('2'), isHoldingSelected: new FormControl(true) }),
  ]);
  @Input() accountIndex: number;
  @Input() viewMode: boolean = true;
  @Input() isParticipantAccount: boolean = false;
  @Output() accountIncluded = new EventEmitter<string>();
  @ViewChildren('holdingCheckbox') holdingsList: QueryList<any>;
  init: boolean = true;
  _holdingsSelectionStates: Array<boolean> = [];

  getGroup(index: number): FormGroup {
    return this.holdingsData.at(index) as FormGroup;
  }

  ngAfterViewInit(): void {
    this.init = false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleHoldingItemChange(): void {
    if (!this.init) {
      if (this.isParticipantAccount) {
        this.emitParticipantHoldingChange();
      } else {
        if (this.areAllHoldingsSelected) {
          this.accountIncluded.emit(HoldingsTableStates.all);
        } else if (this.areSomeHoldingsSelected) {
          this.accountIncluded.emit(HoldingsTableStates.some);
        } else if (this.areNoHoldingsSelected) {
          this.accountIncluded.emit(HoldingsTableStates.none);
        }
      }
    }
  }

  get holdingsSelectionStates(): Array<boolean> {
    if (this.holdingsList) {
      this._holdingsSelectionStates = this.holdingsList?.map((elem) => {
        return elem.elRef.nativeElement.checked;
      });
    }
    return this._holdingsSelectionStates;
  }

  get areAllHoldingsSelected(): boolean {
    return this.holdingsSelectionStates.every((val) => val === true);
  }
  get areSomeHoldingsSelected(): boolean {
    return this.holdingsSelectionStates.some((val) => val === true);
  }
  get areNoHoldingsSelected(): boolean {
    return this.holdingsSelectionStates.every((val) => val === false);
  }

  emitParticipantHoldingChange(): void {
    this.accountIncluded.emit(HoldingsTableStates.participant);
  }
}
